import { default as indexWQ46LhqtMTMeta } from "/root/pages/about/index.vue?macro=true";
import { default as _91name_93K3cN2IIEZqMeta } from "/root/pages/articles/[name].vue?macro=true";
import { default as indexChTtXoRKLyMeta } from "/root/pages/articles/index.vue?macro=true";
import { default as _91name_93pDrri8EkcgMeta } from "/root/pages/catalog/[group]/[name].vue?macro=true";
import { default as indexBVDdmX9ogOMeta } from "/root/pages/catalog/[group]/index.vue?macro=true";
import { default as indexkALUG29lTNMeta } from "/root/pages/catalog/index.vue?macro=true";
import { default as indexzIA3OcqU1mMeta } from "/root/pages/conditioning/air-conditioning-maintenance/index.vue?macro=true";
import { default as indexjKsdNaUeHTMeta } from "/root/pages/conditioning/index.vue?macro=true";
import { default as indexOqtgCEIQeZMeta } from "/root/pages/conditioning/installing-air-conditioner/index.vue?macro=true";
import { default as indexqGLB20egxOMeta } from "/root/pages/conditioning/repair-air-conditioner/index.vue?macro=true";
import { default as indexBlFX4ewCAzMeta } from "/root/pages/contacts/index.vue?macro=true";
import { default as indexpeBUUPJIiAMeta } from "/root/pages/index.vue?macro=true";
import { default as index44VqW2NZ7tMeta } from "/root/pages/ventilation/index.vue?macro=true";
import { default as indexIJdctJT7pMMeta } from "/root/pages/ventilation/installation-ventilation/index.vue?macro=true";
import { default as indexfhOnForLCOMeta } from "/root/pages/ventilation/repair-ventilation/index.vue?macro=true";
import { default as indexfQsTVesKS2Meta } from "/root/pages/ventilation/ventilation-maintenance/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: indexWQ46LhqtMTMeta || {},
    component: () => import("/root/pages/about/index.vue")
  },
  {
    name: "articles-name",
    path: "/articles/:name()",
    meta: _91name_93K3cN2IIEZqMeta || {},
    component: () => import("/root/pages/articles/[name].vue")
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexChTtXoRKLyMeta || {},
    component: () => import("/root/pages/articles/index.vue")
  },
  {
    name: "catalog-group-name",
    path: "/catalog/:group()/:name()",
    meta: _91name_93pDrri8EkcgMeta || {},
    component: () => import("/root/pages/catalog/[group]/[name].vue")
  },
  {
    name: "catalog-group",
    path: "/catalog/:group()",
    meta: indexBVDdmX9ogOMeta || {},
    component: () => import("/root/pages/catalog/[group]/index.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    meta: indexkALUG29lTNMeta || {},
    component: () => import("/root/pages/catalog/index.vue")
  },
  {
    name: "conditioning-air-conditioning-maintenance",
    path: "/conditioning/air-conditioning-maintenance",
    meta: indexzIA3OcqU1mMeta || {},
    component: () => import("/root/pages/conditioning/air-conditioning-maintenance/index.vue")
  },
  {
    name: "conditioning",
    path: "/conditioning",
    meta: indexjKsdNaUeHTMeta || {},
    component: () => import("/root/pages/conditioning/index.vue")
  },
  {
    name: "conditioning-installing-air-conditioner",
    path: "/conditioning/installing-air-conditioner",
    meta: indexOqtgCEIQeZMeta || {},
    component: () => import("/root/pages/conditioning/installing-air-conditioner/index.vue")
  },
  {
    name: "conditioning-repair-air-conditioner",
    path: "/conditioning/repair-air-conditioner",
    meta: indexqGLB20egxOMeta || {},
    component: () => import("/root/pages/conditioning/repair-air-conditioner/index.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: indexBlFX4ewCAzMeta || {},
    component: () => import("/root/pages/contacts/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/pages/index.vue")
  },
  {
    name: "ventilation",
    path: "/ventilation",
    meta: index44VqW2NZ7tMeta || {},
    component: () => import("/root/pages/ventilation/index.vue")
  },
  {
    name: "ventilation-installation-ventilation",
    path: "/ventilation/installation-ventilation",
    meta: indexIJdctJT7pMMeta || {},
    component: () => import("/root/pages/ventilation/installation-ventilation/index.vue")
  },
  {
    name: "ventilation-repair-ventilation",
    path: "/ventilation/repair-ventilation",
    meta: indexfhOnForLCOMeta || {},
    component: () => import("/root/pages/ventilation/repair-ventilation/index.vue")
  },
  {
    name: "ventilation-ventilation-maintenance",
    path: "/ventilation/ventilation-maintenance",
    meta: indexfQsTVesKS2Meta || {},
    component: () => import("/root/pages/ventilation/ventilation-maintenance/index.vue")
  }
]