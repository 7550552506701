<script setup>
const route = useRoute();

useHead({
    titleTemplate: (productCategory) => {
        return productCategory
            ? `${productCategory}`
            : 'Кондиционеры и вентиляция в Щёлково. Все виды работ, продажа, гарантия'
    }
});
</script>

<template>
    <div>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<style lang="less">
.date {
    display: none;
}
</style>
